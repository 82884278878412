import { userConstants } from '../constants';
import { authenticationStateModel } from './stateModels';

const initialState = authenticationStateModel;

export function authentication(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                user: action.user,
                use2fa: action.use2fa,
                readyToValidate: false
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                loggingIn: false,
                loggedIn: true,
                use2fa: action.use2fa,
                readyToValidate: false
            };
        case userConstants.VALIDATE_CODE_FAILURE:
        case userConstants.VALIDATE_CODE_REQUEST:
        case userConstants.LOGIN_RENDER_VALIDATE:
                return {
                    loggingIn: true,
                    loggedIn: false,
                    readyToValidate: true,
                    use2fa: action.use2fa,
                    rememberMe: action.rememberMe,
                    user: action.user
                };            
        case userConstants.LOGIN_FAILURE:
            return {
                loggingIn: false,
                loggedIn: false,
                user: {},
                use2fa: action.use2fa,
                readyToValidate: false
            };
        case userConstants.LOGOUT:
            return {
                loggedIn: false,
                user: {},
                use2fa: action.use2fa
            };        
        case userConstants.VALIDATE_CODE_SUCCESS:
            return {
                loggingIn: false,
                loggedIn: true,
                use2fa: action.use2fa,
                readyToValidate: true
            };
        default:
            return state;
    }
}