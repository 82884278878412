import { http } from "../api/http";
import { getParams } from '../helpers/generalUtils';

export const userService = {
  login,
  logout,
  validate,
  reSendCode
};

function login(username, password, rememberMe, use2fa) {
  
  const use2faUrlPart = use2fa ? "2fa/" : "";
  let url = `/api/user/${use2faUrlPart}signin`;  

  if(getParams("response_mode") === "saml_form_post"){
    url =  `${url}?response_mode=saml_form_post`;
  }
  
  return http.post(url, JSON.stringify({ 
      username, 
      password,
      rememberMe
    }))
    .then(response => {
      return response;
    });
}

function validate(username, userId, rememberMe, code) {
  
  let url = "/api/user/2fa/validate";

  if(getParams("response_mode") === "saml_form_post"){
    url =  `${url}?response_mode=saml_form_post`;
  }
  
  return http.post(url, JSON.stringify({ 
      username, 
      userId,
      rememberMe,
      code      
    }))
    .then(response => {
      return response;
    });
}

function reSendCode(userId, emailAddress, firstName){
    
  let url = "/api/user/2fa/resendcode";
  
  return http.post(url, JSON.stringify({ userId, emailAddress, firstName }))
    .then(response => {
      return response;
  });
}

function logout() {

  const url = "/api/user/signout";

  return http.post(url, JSON.stringify({ 
    username:"username", 
    password:"password"
  }))
  .then(response => {
    return response;
  });
}