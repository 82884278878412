import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ConfigurationBody } from './ConfigurationBody';
import { settingsActions , tenantInfoActions } from '../../actions';
import { UnsavedChangesModal } from './UnsavedChangesModal';

function Configuration() {

    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [settingsState, setSettingsState] = useState([]);
    const [customerInfo, setCustomerInfo] = useState([]);
    const [crmInfo, setCrmInfo] = useState([]);
    const [smtpInfo, setSmtpInfo] = useState([]);
    const [urls, setUrls] = useState([]);
    const [samlCertificate, setSamlCertificate] = useState([]);
    const [crmSchema, setCrmSchema] = useState([]);
    const user = useSelector(state => state.authentication.user);
    const timestampVersion = useSelector(state => state.generalSettings.timestampVersion);
    const [unsavedChanges, setUnsavedChanges] = useState(0);
    const [themeOpenedDropdown, setThemeOpenedDropdown] = useState('');
    const [showSavedToast, setShowSavedToast] = useState(false);
    const [showSaveErrorToast, setShowSaveErrorToast] = useState(false);
    const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
    
    useEffect(() => {             
        // Add class to body element
        document.body.classList.add('bg-light');

        // Load settings
        settingsActions.get().then(settings => setSettingsState(settings)).then(setIsLoading(false));

        // Get information for About tab (Loading is set to false even if this doesn't finish yet)
        tenantInfoActions.getCustomer().then(data => setCustomerInfo(data));
        tenantInfoActions.getCrm().then(data => setCrmInfo(data));
        tenantInfoActions.getSmtp().then(data => setSmtpInfo(data));
        tenantInfoActions.getUrls().then(data => setUrls(data));
        tenantInfoActions.getSamlCertificate().then(data => setSamlCertificate(data));
        tenantInfoActions.getCrmSchema(['contact', 'account']).then(data => setCrmSchema(data));      
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            e = e || window.event;    
            if (e && unsavedChanges > 1 && !showUnsavedChangesModal)
            {
                e.returnValue = 'Leave site?'
                return true;
            }
        }

        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        }
    }, [unsavedChanges, showUnsavedChangesModal]);

    function handleCloseClick(event) {
        if (unsavedChanges > 1 && !showUnsavedChangesModal)
        {
            setShowUnsavedChangesModal(true);
            return;
        }

        // Done this way to avoid code smell
        let newLocation = window.top.location.href;
        window.top.location.href = newLocation;
    }

    function handleStayClick(e) {
        setShowUnsavedChangesModal(false);
    }
    
    function handleSaveClick(event) {
        setShowUnsavedChangesModal(false);
        
        settingsActions.update(settingsState, user.fullName).then(s => { 
            setIsSaving(false); 
            setUnsavedChanges(1);
            setShowSaveErrorToast(false);
            setShowSavedToast(true);
        },
        error => {
            setIsSaving(false); 
            setShowSavedToast(false);
            setShowSaveErrorToast(true);
        });
    } 

    function settingsIframeChange(newSettings) {
        setSettingsState(newSettings);
        setUnsavedChanges(unsavedChanges + 1);
    }

    function handleThemeOpenDropdown(e, id) {
        e.stopPropagation();
        setThemeOpenedDropdown(id);
    }

    function handleThemeCloseDropdown(e) {
        e.stopPropagation();
        setThemeOpenedDropdown('');
    }

    return (
        <div className="h-100" onClick={handleThemeCloseDropdown}>            
            {isLoading && 
            <div style={{display: "flex", justifyContent: "center"}}>
                <div style={{position: "absolute", top: "50%"}}>
                    <i className="fas fa-spinner fa-3x fa-spin"></i>
                </div>
            </div>}
            {!isLoading &&
            <>
            <ConfigurationBody settings={settingsState} 
                            customerInfo={customerInfo} 
                            crmInfo={crmInfo} 
                            smtpInfo={smtpInfo} 
                            urls={urls} 
                            samlCertificate={samlCertificate}
                            isSaving={isSaving}
                            themeOpenedDropdown={themeOpenedDropdown}
                            unsavedChanges={unsavedChanges > 1}
                            showSavedToast={showSavedToast}
                            showSaveErrorToast={showSaveErrorToast}
                            crmSchema={crmSchema}
                            onChange={settingsIframeChange} 
                            onSave={handleSaveClick} 
                            onClose={handleCloseClick}
                            onThemeOpenDropdown={handleThemeOpenDropdown}
                            timestampVersion={timestampVersion}
                            onCloseSavedToast={() => { setShowSavedToast(false); setShowSaveErrorToast(false); }}
                            onIsSavingChange={(s) => setIsSaving(s)}></ConfigurationBody>

            {showUnsavedChangesModal && <UnsavedChangesModal onStay={handleStayClick} onDiscardChanges={handleCloseClick} />}
            </>}       
        </div>
    );
};

export { Configuration };
