import { userConstants, errorConstants } from '../constants';
import { userService } from '../services';
import { alertActions } from './';

export const userActions = {
    login,
    logout,
    validate,
    reSendCode
};

function login(username, password, rememberMe, use2fa) {
    return dispatch => {
                
        dispatch(request({ username }));

        userService.login(username, password, rememberMe, use2fa)
            .then(
                response => {
                    
                    dispatch(success());

                    const action = response.data.action;

                    switch(action){
                        case "redirect":
                            window.location.href = response.data.returnUrl
                            break;
                        case "renderValidate": 
                            dispatch(
                                renderValidate({
                                    userName: username,
                                    userId: response.data.userId,
                                    emailAddress: response.data.emailAddress,
                                    firstName: response.data.firstName,
                                    rememberMe,
                                    use2fa
                                })
                            );
                            break;
                        case "refresh":
                        default: 
                            // eslint-disable-next-line no-self-assign
                            window.location.href = window.location.href;
                            break;
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    
                    if (error === errorConstants.UNAUTHORIZED) {
                        dispatch(alertActions.info(errorConstants.UNAUTHORIZED));
                    } else {
                        dispatch(alertActions.error(error));
                    }
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user, use2fa } }
    function success() { return { type: userConstants.LOGIN_SUCCESS, use2fa } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error, use2fa } }
    function renderValidate(user) { return { type: userConstants.LOGIN_RENDER_VALIDATE, user } }
}

function validate(username, userId, rememberMe, code, emailAddress, use2fa) {
    return dispatch => {
        
        dispatch(validateCodeRequest({
            userName: username, 
            userId: userId, 
            emailAddress: emailAddress,
            rememberMe,
            use2fa
        }));

        userService.validate(username, userId, rememberMe, code)
            .then(
                response => { 
                    dispatch(validateCodeSuccess());                    
                    const action = response.data.action;

                    switch(action){
                        case "redirect":
                            window.location.href = response.data.url
                            break;
                        case "refresh":                            
                        default:
                            // eslint-disable-next-line no-self-assign
                            window.location.href = window.location.href;
                            break;
                    }
                },
                error => {
                    dispatch(validateCodeFailure(error.toString(), {
                        userName: username,
                        userId,
                        emailAddress,
                        rememberMe,
                        use2fa
                    }));

                    if (error === errorConstants.UNAUTHORIZED) {
                        dispatch(alertActions.info(errorConstants.UNAUTHORIZED));
                    } else {
                        dispatch(alertActions.error(error));
                    }
                }
            );
    };

    function validateCodeRequest(user) { return { type: userConstants.VALIDATE_CODE_REQUEST, user } }
    function validateCodeSuccess() { return { type: userConstants.VALIDATE_CODE_SUCCESS } }
    function validateCodeFailure(error, user) { return { type: userConstants.VALIDATE_CODE_FAILURE, error, user } }
}

function reSendCode(userId, emailAddress, firstName) {
    return dispatch => {
                
        dispatch(request(userId, emailAddress));

        userService.reSendCode(userId, emailAddress, firstName)
            .then(
                response => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error.toString()));
                    
                    if (error == errorConstants.UNAUTHORIZED) {
                        dispatch(alertActions.info(errorConstants.UNAUTHORIZED));
                    } else {
                        dispatch(alertActions.error(error));
                    }
                }
            );
    };

    function request(userId, emailAddress) { return { type: userConstants.RESEND_CODE_REQUEST, userId, emailAddress } }
    function success() { return { type: userConstants.RESEND_CODE_SUCCESS } }
    function failure(error) { return { type: userConstants.RESEND_CODE_FAILURE, error } }    
}

function logout(use2fa) {
    return dispatch => {
        userService.logout()
        .then(
            response => {   
                dispatch({ type: userConstants.LOGOUT, use2fa });
                // eslint-disable-next-line no-self-assign
                window.location.href = window.location.href
            }
        );
    }
}