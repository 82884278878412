import React from 'react';
import { StaticRouter } from 'react-router';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import { Login } from './components/Login';
import { User } from './components/User';
import { Configuration } from './components/admin/Configuration';
import { getAuthenticationInfo, isLoggedInUser } from './helpers/rendering';
import 'react-loading-skeleton/dist/skeleton.css'

const AppRoutes = ({ store }) => (  
    
    <Switch>             
      <Route path="/login" render={() => {
        return logicRender(store, "/login");
      }} exact />

      <Route path="/user" render={() => {
        return logicRender(store, "/user");
      }} exact />

      <Route path="/admin" render={() => {
        return logicRender(store, "/admin");
      }} exact />
      <Route
        path="/"
        component={({ location }) => (
          <Redirect
            to={{
              ...location,
              pathname: location.pathname.replace('/', isLoggedInUser(store) ? '/user' : '/login'),
            }}
          />
      )} exact />

      <Redirect
        to={{              
          pathname: isLoggedInUser(store) ? '/user' : '/login',
        }}
      />

    </Switch>  
)

/**
 * 
 * @param {object} store Redux Store Instance
 * @param {string} fromPath Return url
 * @returns 
 */
 function logicRender(store, fromPath){ 
  switch(fromPath)
  {
    case "/user":
      if(isLoggedInUser(store)){
          return (
            <User user={getAuthenticationInfo(store).user}></User>
          )
      } else {
        return (
          <Redirect to="/login" />
        );
      }
    case "/admin":
      if(isLoggedInUser(store)){
          return (
            <Configuration></Configuration>
          )
      } else {
        return (
          <Redirect to="/login" />
        );
      }
    case "/login":
      if(isLoggedInUser(store)){        
          return (
            <Redirect to="/user" />
          );
      } else {
        return (
          <Login />
        );
      }
    default:
      return (
        <Login />
      );
  }
}

function App (props) {  

  return (
          <>
            <div className="w-100 content">
              <div className="subcontent">
                  <div className={"mx-auto" + (props.location == "/admin" ? " h-100" : "")} style={props.location != "/admin" ? {maxWidth: "400px"} : {}}> 
                      {
                        props.location
                        ? (
                          <StaticRouter location={props.location} context={{}}>                      
                              <AppRoutes store={props.store} />
                          </StaticRouter>
                        ) : (
                          <BrowserRouter>                      
                              <AppRoutes store={props.store} />
                          </BrowserRouter>  
                        )
                      }
                  </div>
              </div>
            </div>
        </>
  );
}
export default App;
