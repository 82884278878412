export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_RENDER_VALIDATE: 'LOGIN_RENDER_VALIDATE',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',    
    LOGOUT: 'USERS_LOGOUT',    
    VALIDATE_CODE_REQUEST: 'VALIDATE_CODE_REQUEST',
    VALIDATE_CODE_SUCCESS: 'VALIDATE_CODE_SUCCESS',
    VALIDATE_CODE_FAILURE: 'VALIDATE_CODE_FAILURE',

    RESEND_CODE_REQUEST: 'RESEND_CODE_REQUEST',
    RESEND_CODE_SUCCESS: 'RESEND_CODE_SUCCESS',
    RESEND_CODE_FAILURE: 'RESEND_CODE_FAILURE',

    FORM_SUBMIT: "FORM_SUBMIT"
};
