import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions, alertActions } from '../actions';
import { errorConstants } from '../constants/error.constants';
import { systemConstants } from '../constants/system.constants';
import AuthCode from 'react-auth-code-input';

function Validate() {

    /**
     * Countdown Timer constants
    */
    const [minutes, setMinutes ] = useState(systemConstants.INITIAL_MINUTES_OTP_TIME);
    const [seconds, setSeconds ] =  useState(systemConstants.INITIAL_SECONDS_OTP_TIME);    
    let disabledReSendCodeButton = !(minutes ===0 && seconds===0);
    let minutesText = minutes < 10 ? `0${minutes}` : minutes;
    let secondsText = seconds < 10 ? `0${seconds}` : seconds;
    let textCountDown = minutes === 0 && seconds === 0 ? "0:00" : `${minutesText}:${secondsText}`;

    /**
     * Validate constants
     */
    const [submitted, setSubmitted] = useState(false);
    const [code, setCode] = useState('');        
    const [codeEntered, setCodeEntered] = useState(false);
    const alert = useSelector(state => state.alert);
    const user2fa = useSelector(state => state.authentication.user);
    const generalSettings = useSelector(state => state.generalSettings);
    const mfaSettings = useSelector(state => state.mfaSettings);
    const dispatch = useDispatch();            
    const authInputRef = useRef();
    const authCharsLength = 6;

    /**
     * Set State
     * @param {string} targetCodeValue OTP Code
    */
    function handleChange(targetCodeValue) {
        if (alert.type){
            dispatch(alertActions.clear());
        }
        setCode(targetCodeValue);
        setCodeEntered(targetCodeValue.length == authCharsLength);
    }

    /**
     * Distpach ReSend Code 
     */
    function handleReSendCode() {        
        setSubmitted(false);
        dispatch(
            userActions.reSendCode(user2fa.userId, user2fa.emailAddress, user2fa.firstName)
        );
        setMinutes(systemConstants.INITIAL_MINUTES_OTP_TIME);
        setSeconds(systemConstants.INITIAL_SECONDS_OTP_TIME);
        authInputRef.current.clear();
    }

    /**
     * Distpach Validation OTP Code Action
     * @param {object} e Event Object
     */
    function handleSubmit(e) {
        e.preventDefault();
        
        setSubmitted(true);

        if (code) {
            dispatch(
                userActions.validate(
                    user2fa.userName,
                    user2fa.userId, 
                    user2fa.rememberMe, 
                    code,
                    user2fa.emailAddress,
                    user2fa.use2fa
                )
            );
        }
    }
                   
    /**
     * Use Effect to evaluate the countdown
    */
    useEffect(()=>{
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            } 
        }, 1000)
        return ()=> {
            clearInterval(myInterval);
        };
    });
        
    return (
        <React.Fragment>
            <div className="content-box">
                <div className="p-4">
                    <div className="text-center mb-4">
                        <img alt ="" src={generalSettings.images.logo} style={generalSettings.images.logoWidth ? {width: generalSettings.images.logoWidth} : {}} />
                        <h3 className="mt-2">{mfaSettings.showTitle && mfaSettings.title}</h3>
                        {mfaSettings.showSubtitle && <h5>{mfaSettings.subtitle}</h5>}
                    </div>
                    {mfaSettings.instructionsText && <p>{mfaSettings.instructionsText}</p>}
                    {alert.type &&
                        <div className="text-danger my-3">{alert.message === errorConstants.UNAUTHORIZED ? mfaSettings.messages.invalidCode : ""}</div>
                    }
                    <form name="form" onSubmit={handleSubmit}>
                        <div className="mb-1">
                            <p><div dangerouslySetInnerHTML={{ __html: mfaSettings.formTexts.codeSent.replace('{0}', textCountDown)}} /></p>
                            <p>{mfaSettings.formTexts.enterCode}</p>
                            <AuthCode onChange={handleChange} 
                                characters={authCharsLength}
                                className={'form-control' + (submitted && !code ? ' is-invalid' : '')} 
                                containerClassName="mfa-container"
                                inputClassName="mfa-input"
                                allowedCharacters = "numeric"
                                name="code"
                                ref={authInputRef}
                            />
                        </div>
                        <div>
                            <div className="d-grid gap-2">
                                <button className="btn btn-primary" disabled={!codeEntered || !disabledReSendCodeButton}>
                                    {mfaSettings.formTexts.verifyButton}
                                </button>                                
                            </div>
                        </div>
                    </form>
                    <br></br>
                    <p>{mfaSettings.formTexts.didntReceiveCode}</p> 
                    <div className="d-grid gap-2">
                        <button className="btn btn-secondary" disabled={disabledReSendCodeButton} onClick={handleReSendCode}>
                            {mfaSettings.formTexts.resendCodeButton}
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>   
    );
}

export { Validate };