import React, { useState, useEffect } from 'react';

function About(props) {

    const [customerInfo, setCustomerInfo] = useState([]);
    const [crmInfo, setCrmInfo] = useState([]);
    const [smtpInfo, setSmtpInfo] = useState([]);
    const [urls, setUrls] = useState([]);

    useEffect(() => {
        setCustomerInfo(props.customerInfo);
        setCrmInfo(props.crmInfo);
        setSmtpInfo(props.smtpInfo);
        setUrls(props.urls);
    }, []);

    return (
        <>
            {customerInfo.name &&
            <>
            <h4 className="text-primary">About</h4>
            <hr />
            <h5 className="text-primary">Customer Information</h5>
            <table class="table table-bordered bg-white mb-4 mt-3"> 
                <tbody>
                    <tr>
                        <th scope="row" className="w-25">Customer Name</th>
                        <td>{customerInfo.name}</td>                    
                    </tr>
                    <tr>
                        <th scope="row">Acronym</th>
                        <td>{customerInfo.acronym}</td>
                    </tr>
                    <tr>
                        <th scope="row">Web Site URL</th>
                        <td>{customerInfo.webSiteUrl}</td>            
                    </tr>            
                </tbody>
            </table>
            <hr />
            </>
            }
            {crmInfo.url &&
            <>
            <h5 className="text-primary">CRM Information</h5>
            <table class="table table-bordered bg-white mb-4 mt-3"> 
                <tbody>
                    <tr>
                        <th scope="row" className="w-25">URL</th>
                        <td>{crmInfo.url}</td>                    
                    </tr>                    
                </tbody>
            </table>
            <hr />
            </>
            }            
            <h5 className="text-primary">SMTP Information</h5>
            {smtpInfo && smtpInfo.accessId &&
            <table class="table table-bordered bg-white mb-4 mt-3"> 
                <tbody>
                    <tr>
                        <th scope="row" className="w-25">Access Id</th>
                        <td>{smtpInfo.accessId}</td>                    
                    </tr>                    
                </tbody>
            </table>
            }
            {(!smtpInfo || !smtpInfo.accessId) &&
            <>SMTP is not configured yet.</>
            }
            <hr />
            {urls &&
            <>
            <h5 className="text-primary">URLs</h5>
            <table class="table table-bordered bg-white mt-3"> 
                <tbody>
                    {urls.map(url => <tr><td>{url}</td></tr>)}
                </tbody>
            </table>  
            </>
            }          
        </>
    )
}

export { About };
