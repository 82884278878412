import React, { useState, useEffect} from 'react';
import { Header } from './Header';
import { LeftMenu } from './LeftMenu';
import { General } from './General';
import { SSOMainNav } from './SSOMainNav';
import { Applications } from './Applications';
import { TokenIntegration } from './TokenIntegration';
import { SamlIntegration } from './SamlIntegration';
import { About } from './About';
import { ThemesMainNav } from './ThemesMainNav';
import { ThemesSiteNav } from './ThemesSiteNav';
import { ThemesSiteGeneral } from './ThemesSiteGeneral';
import { ThemesSiteImages } from './ThemesSiteImages';
import { ThemesSiteStyling } from './ThemesSiteStyling';
import { ThemesSiteHeader } from './ThemesSiteHeader';
import { ThemesSiteFooter } from './ThemesSiteFooter';
import { ThemesPagesNav } from './ThemesPagesNav';
import { ThemesAbout } from './ThemesAbout';
import { GlobalSettings } from './GlobalSettings';
import { BasicSettings } from './BasicSettings';
import { Login } from './Login';
import { MFA } from './MFA';
import { assetsActions, settingsActions } from '../../actions';
import { commonConstants } from '../../constants/common.constants';
import { assetTypeConstants } from '../../constants/assetType.constants';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';

function ConfigurationBody(props) {

    const [initialized, setInitialized] = useState(false);
    const [settingsState, setSettingsState] = useState([]);    
    const [level1, setLevel1] = useState('site');
    const [level2, setLevel2] = useState('sso');
    const [themeId, setThemeId] = useState('');
    const [level3, setLevel3] = useState('applications');
    const [originalImages, setOriginalImages] = useState([]);
    const [pendingCommitImages, setPendingCommitImages] = useState([]);
    const [displaySecondLevelIcon, setDisplaySecondLevelIcon] = useState(false);
    const [customerInfo, setCustomerInfo] = useState([]);
    const [crmInfo, setCrmInfo] = useState([]);
    const [smtpInfo, setSmtpInfo] = useState([]);
    const [urls, setUrls] = useState([]);
    const [samlCertificate, setSamlCertificate] = useState([]);
    const [defaultThemeValues, setDefaultThemeValues] = useState([]);
    const [displaySSOMainNav, setDisplaySSOMainNav] = useState([]);

    useEffect(() => {
        if (!initialized && props.settings.site) {
            setSettingsState(props.settings);
            setInitialized(true);
            setOriginalImages(props.settings.themes.map((theme) => { return { id: theme.id, images: theme.site.images } }));

            settingsActions.themeDefaultValues().then(value => {
                setDefaultThemeValues(value);
            });
        }
    }, [props.settings]);

    useEffect(() => {
        setCustomerInfo(props.customerInfo);
    }, [props.customerInfo]);

    useEffect(() => {
        setCrmInfo(props.crmInfo);
    }, [props.crmInfo]);

    useEffect(() => {
        setSmtpInfo(props.smtpInfo);
    }, [props.smtpInfo]);

    useEffect(() => {
        setUrls(props.urls);
    }, [props.urls]);

    useEffect(() => {
        setSamlCertificate(props.samlCertificate);
    }, [props.samlCertificate]);

    useEffect(() => { 
        if (initialized)
        {
            props.onChange(settingsState);
        }
    }, [settingsState]);

    function handleMenuClick(l1, l2, theme) { 
        setLevel1(l1);
        setLevel2(l2);

        if (theme)
            setSelectedTheme(theme);

        if (l2 == 'sso')
            setLevel3('applications');
    }   

    function handleThemeAdd(e, themeName) {
        let tempSettings = {...settingsState};

        // Increment the themeLastId index
        tempSettings.site.themeLastId = tempSettings.site.themeLastId + 1;

        // Set default values
        let newTheme = {...defaultThemeValues};
        newTheme.name = themeName;
        newTheme.id = tempSettings.site.themeLastId;

        tempSettings.themes.push(newTheme);

        // Sort alphabetically
        tempSettings.themes.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

        setSettingsState(tempSettings);

        // Change to new theme
        setSelectedTheme(newTheme.id);
    }

    function handleThemeDuplicate(e, id, themeName) {
        let tempSettings = {...settingsState};

        // Increment the themeLastId index
        tempSettings.site.themeLastId = tempSettings.site.themeLastId + 1;

        // Copy from selected theme
        let newTheme = {...settingsState.themes.find(t => t.id == id)};
        newTheme.name = themeName;
        newTheme.id = tempSettings.site.themeLastId;

        tempSettings.themes.push(newTheme);

        // Copy images
        let pendingCommitImagesForTheme = pendingCommitImages.find(t => t.id == id);
        let tempPendingCommitImages = pendingCommitImages;
        let commitTokens = [];
        var promises = [];
        Object.entries(newTheme.site.images).forEach(([key, value]) => { 
            if (key != "logoWidth") {

                if (pendingCommitImagesForTheme && pendingCommitImagesForTheme.images[key] && pendingCommitImagesForTheme.images[key].commitToken && pendingCommitImagesForTheme.images[key].upload)
                {
                    promises.push(
                        copyImage('temp', pendingCommitImagesForTheme.images[key].commitToken + '.file', newTheme.id).then((response) => {
                            commitTokens.push({key, commitToken: response});
                        })
                    );
                }
                else if (value && !value.startsWith('http'))
                {
                    promises.push(
                        copyImage('tenant', id + '/' + value, newTheme.id).then((response) => {
                            commitTokens.push({key, commitToken: response});
                        })
                    );
                }
            }
        });

        // Update temp images with copyImage tokens
        Promise.all(promises).then(() => {
            if (tempPendingCommitImages.filter(p => p.id == newTheme.id).length == 0)
                tempPendingCommitImages.push({id: newTheme.id, images: {}});

            commitTokens.forEach(token => {
                let previewUrl = `${commonConstants.BASE_URL}/${commonConstants.ASSETS_GET_SERVICE_URL}?type=${assetTypeConstants.TEMP}&filePath=${token.commitToken}.file`;
                tempPendingCommitImages = tempPendingCommitImages.map(p => p.id == newTheme.id ? { ...p, images: {...p.images , [token.key]: { ...p.images[token.key], commitToken: token.commitToken, upload: true }}} : p);
                tempSettings.themes = tempSettings.themes.map(t => (t.id === newTheme.id ? { ...t, site: {...t.site, images: {...t.site.images, [token.key]: previewUrl}}} : t));
            });

            setPendingCommitImages(tempPendingCommitImages);
            setSettingsState(tempSettings);
        });      

        // Sort alphabetically
        tempSettings.themes.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

        setSettingsState(tempSettings);

        // Change to new theme
        setSelectedTheme(newTheme.id);
    }

    function copyImage(type, filePath, destinationTheme) {
        let copyRequest = { type: type,
                            filePath: filePath,
                            destinationTheme: destinationTheme.toString()
                          };

        return assetsActions.copy(copyRequest);
    }

    function handleThemeRename(e, id, newName) {
        let tempSettings = {...settingsState};
        tempSettings.themes = tempSettings.themes.map(t => (t.id === id ? { ...t, name: newName } : t));

        setSettingsState(tempSettings);
    }

    function handleThemeDelete(id) {
        let tempSettings = {...settingsState};
        tempSettings.themes = settingsState.themes.filter(themes => themes.id != id);

        let tempPendingCommitImages = pendingCommitImages;
        if (Array.isArray(tempPendingCommitImages))
        {
            tempPendingCommitImages = tempPendingCommitImages.filter(pci => pci.id != id);
            setPendingCommitImages(tempPendingCommitImages);
        }

        // If active theme is deleted, change in selectedTheme
        if (themeId == id) setSelectedTheme(tempSettings.site.theme);
        
        setSettingsState(tempSettings);
    }

    function handleSetAsSiteTheme(e, id) {
        let tempSettings = {...settingsState};
        tempSettings.site.theme = id;
        setSettingsState(tempSettings);
    }

    function setSelectedTheme(id) {
        setLevel1('themes');
        setLevel2('site');        
        setLevel3('general');
        setThemeId(id);
    }
    
    function handleScroll(e) {
        setDisplaySecondLevelIcon(e.target.scrollTop > 55);
    }

    function handleChangeTwoLevels(l1, l2, key, value) {
        if (l2)
            setSettingsState(settings => ({ ...settings, [l1]: {...settings[l1], [l2]: { ...settings[l1][l2], [key]: value }}}));
        else
            setSettingsState(settings => ({ ...settings, [l1]: {...settings[l1],  [key]: value }}));
    }

    function handleMfaChange(mfaType, key, value)
    {
        if (settingsState.site.mfa && settingsState.site.mfa.find(a => a.type == mfaType))
            setSettingsState(settings => ({ ...settings, site: {...settings.site, mfa: settings.site.mfa.map(a => a.type == mfaType ? {...a, [key]: value } : a) }}));
        else
        {
            let currentMfaArray = settingsState.site.mfa;
            if (!Array.isArray(currentMfaArray))
                currentMfaArray = [];

            currentMfaArray.push({ type: mfaType, [key]: value });
            setSettingsState(settings => ({ ...settings, site: {...settings.site, mfa: currentMfaArray }}));
        }
    }

    function handleChangeThreeLevels(l1, l2, l3, key, value) {
        setSettingsState(settings => ({ ...settings, [l1]: {...settings[l1], [l2]: { ...settings[l1][l2], [l3]: { ...settings[l1][l2][l3], [key]: value }}}}));
    }

    function handleApplicationsSave(e, applicationsSettings) {
        setSettingsState(settings => ({ ...settings, site: {...settings.site, sso : {...settings.site.sso, applications: applicationsSettings }}}));
    }

    function handleDisplayMainNavChange(display) {
        setDisplaySSOMainNav(display);
    }

    function handleGlobalSettingsChange(e) {
        let key = Object.keys(e)[0];
        let tempSettings = {...settingsState};
        tempSettings.themes = tempSettings.themes.map(t => (t.id === themeId ? { ...t, pages: {...t.pages, [level2]: {...t.pages[level2], [key]: e[key]} }} : t));

        setSettingsState(tempSettings);
    }

    function handlePageSettingsChange(e, l3) {
        let key = Object.keys(e)[0];
        let tempSettings = {...settingsState};

        tempSettings.themes = tempSettings.themes.map(t => (t.id === themeId ? { ...t, pages: {...t.pages, [level2]: {...t.pages[level2], [l3]: {...t.pages[level2][l3], [key]: e[key]} }}} : t));
        setSettingsState(tempSettings);
    }

    function handleLevel2Click(l2) {
        setLevel2(l2);

        if (l2 == 'site') 
            setLevel3('general');
        else
            setLevel3('global');
    }

    function handleLevel3Click(l3) {
        setLevel3(l3);
    }

    function handleThemeChangeFirstLevel(l2, key, value) {
        let tempSettings = {...settingsState};
        tempSettings.themes = tempSettings.themes.map(t => (t.id === themeId ? { ...t, [l2]: {...t[l2], [key]: value} } : t));
        setSettingsState(tempSettings);
    }

    function handleThemeChangeSecondLevel(l2, l3, key, value) {
        let tempSettings = {...settingsState};
        tempSettings.themes = tempSettings.themes.map(t => (t.id === themeId ? { ...t, [l2]: {...t[l2], [l3]: {...t[l2][l3], [key]: value}}} : t));
        setSettingsState(tempSettings);
    }

    function handleImageSourceChange(imageSource, image) {
        let tempPendingCommitImages = pendingCommitImages;

        // Add this theme to pending images
        if (tempPendingCommitImages.filter(p => p.id == themeId).length == 0) 
            tempPendingCommitImages.push({id: themeId, images: {}});

        if (imageSource == 'url'|| imageSource == 'noImage') {
            // Mark image to avoid commit
            tempPendingCommitImages = tempPendingCommitImages.map(p => p.id == themeId ? { ...p, images: {...p.images , [image]: { ...p.images[image], upload: false }}} : p);

            if (imageSource == 'noImage')
            {
                // Clear image on settings
                handleImageUrlChange('', image);
            }
        }
        else
        {
            // Mark image to commit in case user uploaded one
            tempPendingCommitImages = tempPendingCommitImages.map(p => p.id == themeId ? { ...p, images: {...p.images , [image]: { ...p.images[image], upload: true }}} : p);
        }


        setPendingCommitImages(tempPendingCommitImages);
    }

    function handleImageUpload(theme, commitToken, previewUrl, image) {
        let tempPendingCommitImages = pendingCommitImages;

        // Add this theme to pending images
        if (tempPendingCommitImages.filter(p => p.id == theme).length == 0)
            tempPendingCommitImages.push({id: theme, images: {}});

        tempPendingCommitImages = tempPendingCommitImages.map(p => p.id == theme ? { ...p, images: {...p.images , [image]: { ...p.images[image], commitToken, upload: true }}} : p);
        setPendingCommitImages(tempPendingCommitImages);

        // Change settings state
        let tempSettings = {...settingsState};
        tempSettings.themes = tempSettings.themes.map(t => (t.id === theme ? { ...t, site: {...t.site, images: {...t.site.images, [image]: previewUrl}}} : t));

        setSettingsState(tempSettings);
    }

    function handleImageUrlChange(imageUrl, image) {
        let tempSettings = {...settingsState};
        tempSettings.themes = tempSettings.themes.map(t => (t.id === themeId ? { ...t, site: {...t.site, images: {...t.site.images, [image]: imageUrl}}} : t));

        setSettingsState(tempSettings);
    }

    function handleLogoWidthChange(value) {
        let tempSettings = {...settingsState};
        tempSettings.themes = tempSettings.themes.map(t => (t.id === themeId ? { ...t, site: {...t.site, images: {...t.site.images, logoWidth: value }}} : t));

        setSettingsState(tempSettings);
    }
    
    function handleSave(e) {
        props.onIsSavingChange(true);

        // Commit changed images
        var promises = [];
        pendingCommitImages.forEach((theme) => {
            Object.entries(theme.images).forEach(([key, value]) => {
                if (value.commitToken && value.upload)
                {
                    promises.push(
                        assetsActions.commit(value.commitToken).then(
                            name => 
                            {
                                let settings = {...settingsState};
                                let filename = name.split('/').pop();
                            
                                // Delete original image replaced with another image
                                let originalTheme = originalImages.find(oi => oi.id == theme.id);
                                if (originalTheme)
                                {
                                    let originalImage = originalTheme.images[key];
                                    if (originalImage && !originalImage.startsWith('http') && filename != originalImage)
                                        assetsActions.del(theme.id + '/' + originalImage);
                                }

                                if (settings.themes.find(t => t.id == theme.id))
                                {
                                    settings.themes.find(t => t.id == theme.id).site.images[key] = filename;
                                    setSettingsState(settings);
                                }
                            }
                        )
                    );
                }
            }); 
        });       

        // After all images are commited, then Save
        Promise.all(promises).then(() => 
            {
                // Delete images that are replaced with URLs (including blank Urls)
                originalImages.forEach((theme) => {
                    Object.entries(theme.images).forEach(([key, value]) => {
                        if ((!settingsState.themes.find(t => t.id == theme.id) || settingsState.themes.find(t => t.id == theme.id).site.images[key].startsWith("http") || !settingsState.themes.find(t => t.id == theme.id).site.images[key]) && value && !value.startsWith('http'))
                            assetsActions.del(theme.id + '/' + value);
                    });
                });                

                setPendingCommitImages([]);
                setOriginalImages(settingsState.themes.map((theme) => { return { id: theme.id, images: theme.site.images } }));
                props.onSave();
            }
        );
    }

    function getToastBackgroundClass(){
        if (props.showSavedToast) return "bg-primary";
        if (props.showSaveErrorToast) return "bg-danger";
        return "d-none";
    }

    let currentThemeSettings = settingsState.themes ? settingsState.themes.find(t => t.id == themeId) : {};
    let currentThemeName = currentThemeSettings ? currentThemeSettings.name : '';

    return (
        <div className="container-fluid h-100 d-flex flex-column">
            <Header level1={level1} level2={level2} themeName={currentThemeName} isSaving={props.isSaving} 
                    lastModifiedOn={settingsState.lastModifiedOn} lastModifiedBy={settingsState.lastModifiedBy} 
                    onSave={handleSave} onClose={props.onClose} unsavedChanges={props.unsavedChanges} /> 

            {settingsState.site && 
            <div className="row flex-fill mt-3 px-3" style={{minHeight: '0px'}}>
                <LeftMenu level1={level1} level2={level2} selectedThemeId={themeId} settings={settingsState} 
                          onMenuClick={handleMenuClick} onThemeAdd={handleThemeAdd} onThemeRename={handleThemeRename} 
                          onThemeDuplicate={handleThemeDuplicate} onThemeDelete={handleThemeDelete} onSetAsSiteTheme={handleSetAsSiteTheme}
                          themeOpenedDropdown={props.themeOpenedDropdown} onThemeOpenDropdown={props.onThemeOpenDropdown} />

                <div className="col p-3 pt-0 mh-100" style={{overflowY: "auto"}} onScroll={handleScroll}>
                    {level1 == 'site' && level2 == 'general' && 
                    <General settings={settingsState.site} themes={settingsState.themes} onChange={handleChangeTwoLevels} onMfaChange={handleMfaChange} />
                    }

                    {level1 == 'site' && level2 == 'sso' && 
                    <>
                    {displaySSOMainNav && <SSOMainNav active={level3} onClick={handleLevel3Click} />}

                        {level3 == 'applications' &&
                        <Applications settings={settingsState.site.sso.applications ? settingsState.site.sso.applications : []} 
                                      crmSchema={props.crmSchema}
                                      themes={settingsState.themes} 
                                      onSave={handleApplicationsSave} 
                                      onDisplayMainNavChange={handleDisplayMainNavChange}
                                      unsavedChanges={props.unsavedChanges}></Applications>
                        }                

                        {level3 == 'token' &&
                        <TokenIntegration settings={settingsState.site.sso.token} onChange={handleChangeThreeLevels}></TokenIntegration>
                        } 

                        {level3 == 'saml' &&
                        <SamlIntegration settings={settingsState.site.sso.saml} onChange={handleChangeThreeLevels} samlCertificate={samlCertificate}></SamlIntegration>
                        }

                    </>
                    }                    

                    {level1 == 'site' && level2 == 'about' &&
                    <About customerInfo={customerInfo} crmInfo={crmInfo} smtpInfo={smtpInfo} urls={urls}></About>
                    }

                    {level1 == 'themes' && 
                    <>
                    <ThemesMainNav active={level2} onClick={handleLevel2Click} />

                        {level2 == 'site' && 
                        <>
                        <ThemesSiteNav active={level3} displaySecondLevelIcon={displaySecondLevelIcon} onClick={handleLevel3Click} ></ThemesSiteNav>
                        
                            {level3 == 'general' && 
                            <ThemesSiteGeneral settings={currentThemeSettings.site} onChange={handleThemeChangeFirstLevel}></ThemesSiteGeneral>
                            }

                            {level3 == 'images' && 
                            <ThemesSiteImages settings={currentThemeSettings.site} 
                                            onImageSourceChange={handleImageSourceChange}
                                            onImageUpload={handleImageUpload}
                                            onImageUrlChange={handleImageUrlChange}
                                            onLogoWidthChange={handleLogoWidthChange}
                                            theme={themeId}
                                            timestampVersion={props.timestampVersion}>
                            </ThemesSiteImages>
                            }

                            {level3 == 'styling' && 
                            <ThemesSiteStyling settings={currentThemeSettings.site} onChange={handleThemeChangeSecondLevel}></ThemesSiteStyling>
                            }

                            {level3 == 'header' && 
                            <ThemesSiteHeader settings={currentThemeSettings.site} onChange={handleThemeChangeSecondLevel}></ThemesSiteHeader>
                            }

                            {level3 == 'footer' && 
                            <ThemesSiteFooter settings={currentThemeSettings.site} onChange={handleThemeChangeSecondLevel}></ThemesSiteFooter>
                            }

                        </>
                        }

                        {level2 == 'about' && 
                        <ThemesAbout settings={settingsState} themeId={currentThemeSettings.id}></ThemesAbout>
                        }

                        {level2 != 'site' && level2 != 'about'  &&
                        <>
                        <ThemesPagesNav 
                                        level2={level2}
                                        level3={level3} 
                                        secondLevelIcon={level2 == 'login' ? 'fas fa-sign-in-alt' : ''} 
                                        displaySecondLevelIcon={displaySecondLevelIcon}
                                        onLevel3Click={handleLevel3Click}>
                        </ThemesPagesNav>

                            {level3 == 'global' && 
                            <GlobalSettings settings={currentThemeSettings.pages[level2]} onChange={handleGlobalSettingsChange}></GlobalSettings>
                            }

                            {level3 == 'basic' && 
                            <BasicSettings prompts={currentThemeSettings.pages[level2].prompts} onChange={handlePageSettingsChange}></BasicSettings>
                            }

                            {level3 == 'advanced' && 
                            <>
                                {level2 == 'login' &&
                                    <Login settings={currentThemeSettings.pages.login} onChange={handlePageSettingsChange}></Login>
                                }
                                {level2 == 'mfa' &&
                                    <MFA settings={currentThemeSettings.pages.mfa} onChange={handlePageSettingsChange}></MFA>
                                }
                            </>
                            }

                        </>
                        }
                    </>
                    }
                    <ToastContainer position="bottom-end" className="p-3">
                        <Toast className="w-auto" onClose={props.onCloseSavedToast} show={props.showSavedToast || props.showSaveErrorToast} delay={5000} autohide>
                            <Toast.Body className={getToastBackgroundClass() + " text-white fs-6"}>
                                {props.showSavedToast ? "Configuration was saved successfully." : ""}
                                {props.showSaveErrorToast ? "Error while saving configuration. Please try again later." : ""}
                                <button type="button" className="btn-close btn-close-white ms-5 me-2 m-auto float-end" onClick={props.onCloseSavedToast}></button>
                            </Toast.Body>
                        </Toast>                        
                    </ToastContainer>                    
                </div>
            </div>
            }            
        </div>
    );
}

export { ConfigurationBody };