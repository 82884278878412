import React from 'react';
import { Fieldset } from './Fieldset';

function MFA(props) {
    function handleChange(e, fieldset) {
        let { name, value } = e.target;

        if (e.target.type == "checkbox")
            value = e.target.checked;

        // Update parent's state
        props.onChange({[name]: value}, fieldset);
    }    

    return (
        <>
        <h4 className="text-primary">Advanced Settings</h4>
        <hr />
        <Fieldset title="Form Text">
            <>
            <div className="row mb-3">
                <div className="col-6">
                    <label htmlFor="codeSent" className="form-label">Code sent text</label>
                    <input type="text" className="form-control" name="codeSent" id="codeSent" value={props.settings.formTexts.codeSent} onChange={(e) => handleChange(e, 'formTexts')} />
                </div>
                <div className="col-6">
                    <label htmlFor="enterCode" className="form-label">Enter code text</label>
                    <input type="text" className="form-control" name="enterCode" id="enterCode" value={props.settings.formTexts.enterCode} onChange={(e) => handleChange(e, 'formTexts')} />
                </div>
            </div>     
            <div className="row mb-3">
                <div className="col-6">
                    <label htmlFor="verifyButton" className="form-label">Verify button text</label>
                    <input type="text" className="form-control" name="verifyButton" id="verifyButton" value={props.settings.formTexts.verifyButton} onChange={(e) => handleChange(e, 'formTexts')} />
                </div>
                <div className="col-6">
                    <label htmlFor="didntReceiveCode" className="form-label">Didn't receive code text</label>
                    <input type="text" className="form-control" name="didntReceiveCode" id="didntReceiveCode" value={props.settings.formTexts.didntReceiveCode} onChange={(e) => handleChange(e, 'formTexts')} />
                </div>
            </div> 
            <div className="row mb-3">                
                <div className="col-6">
                    <label htmlFor="resendCodeButton" className="form-label">Resend code button text</label>
                    <input type="text" className="form-control" name="resendCodeButton" id="resendCodeButton" value={props.settings.formTexts.resendCodeButton} onChange={(e) => handleChange(e, 'formTexts')} />
                </div>
                <div className="col-6">
                    <label htmlFor="emailSubject" className="form-label">Email subject</label>
                    <input type="text" className="form-control" name="emailSubject" id="emailSubject" value={props.settings.formTexts.emailSubject} onChange={(e) => handleChange(e, 'formTexts')} />
                </div>
            </div> 
            <div className="row mb-3">                
                <div className="col-6">
                    <label htmlFor="emailGreeting" className="form-label">Email greeting</label>
                    <input type="text" className="form-control" name="emailGreeting" id="emailGreeting" value={props.settings.formTexts.emailGreeting} onChange={(e) => handleChange(e, 'formTexts')} />
                </div>
                <div className="col-6">
                    <label htmlFor="emailIntroduction" className="form-label">Email introduction</label>
                    <input type="text" className="form-control" name="emailIntroduction" id="emailIntroduction" value={props.settings.formTexts.emailIntroduction} onChange={(e) => handleChange(e, 'formTexts')} />
                </div>
            </div>
            <div className="row mb-3">                
                <div className="col-6">
                    <label htmlFor="emailDidntRequest" className="form-label">Email didn't request</label>
                    <input type="text" className="form-control" name="emailDidntRequest" id="emailDidntRequest" value={props.settings.formTexts.emailDidntRequest} onChange={(e) => handleChange(e, 'formTexts')} />
                </div>
            </div>
            </>
        </Fieldset>
        <Fieldset title="Messages">
            <>
            <div className="mb-3">
                <label htmlFor="invalidCode" className="form-label">Invalid code</label>
                <textarea className="form-control" name="invalidCode" id="invalidCode" value={props.settings.messages.invalidCode} onChange={(e) => handleChange(e, 'messages')} />
            </div>
            </>
        </Fieldset>
        </>
    );
}

export { MFA };